import {
    Button,
    FormControlLabel,
    Grid,
    Switch,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { Add } from '@mui/icons-material'
import { useLocation } from '@reach/router'
import { Link, navigate } from 'gatsby'
import queryString from 'query-string'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { ITool } from '../../interfaces/tool.interface'
import { removeComparedToolId } from '../../state/actions/tools/remove-compared-tool-id'
import { setComparedToolIds } from '../../state/actions/tools/set-compared-tool-ids'
import { AppDispatchType } from '../../state/interfaces/app-dispatch.type'
import { removeComparedToolFromLS, replaceComparedToolsInLS } from '../../utils/local-storage'
import { IToolMetaFragment } from '../../fragments/tool-meta.fragment'
import RemoveIcon from './assets/clear.svg'
import { get } from 'lodash'

const order = [
    'attributes.code',
    'attributes.name_key',
    'attributes.model_key',
    'attributes.design_key',
    'attributes.alignment_key',
    'attributes.nominal_height',
    'attributes.gear_ratio_key',
    'attributes.tool_holder_type_key',
    'attributes.tool_holder_standard_key',
    'attributes.tool_holder_size_key',
    'attributes.spindle_type_key',
    'attributes.coolant_supply_key',
    'attributes.speed',
    'attributes.torque',
]

const alwaysDiff = ['code', 'name']

const StyledContainer = styled.div`
    padding: 32px var(--layout-side-padding) var(--layout-side-padding);

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding: 20px var(--layout-side-padding) var(--layout-side-padding);
    }
`

const StyledTitleTypography = styled(Typography)`
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 20px;
    }
`

const StyledActions = styled.div`
    button:not(:last-child) {
        margin-right: 16px;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: flex;
        flex-direction: column;

        button:not(:last-child) {
            margin-right: 0;
            margin-bottom: 12px;
        }
    }
`

const StyledDesktopButton = styled(Button)`
    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: none;
    }
`

const StyledDiffFormControlLabel = styled(FormControlLabel)`
    margin-top: 12px;
    display: none;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: block;
    }
`

const StyledTable = styled.table<{ columnsCount: number }>`
    border: 1px solid var(--grey-300-color);
    display: grid;
    grid-template-columns: ${({ columnsCount }) => `repeat(${columnsCount}, minmax(150px, 1fr))`};
    background-color: var(--grey-300-color);
    grid-gap: 1px;
    margin: 40px 0;
    max-width: 100vw;
    overflow-x: scroll;

    thead,
    tbody,
    tr {
        display: contents;
    }

    * {
        box-sizing: border-box;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        margin-top: 20px;
        grid-template-columns: ${({ columnsCount }) =>
            `repeat(${columnsCount}, minmax(120px, 1fr))`};
    }
`

const commonCells = css`
    display: flex;
    align-items: center;
    background-color: white;
`

const StyledHead = styled.thead``

const StyledTr = styled.tr`
    cursor: pointer;
`

const StyledHeaderTr = styled(StyledTr)`
    cursor: initial;
`

const StyledTh = styled.th`
    ${commonCells}
    justify-content: center;
    padding: 20px;
    position: relative;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding: 20px;
    }
`

const StyledRemoveIcon = styled(RemoveIcon)`
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
        path {
            fill-opacity: 0.87;
        }
    }
`

const StyledBody = styled.tbody``

interface IStyledText {
    readonly $isLabel: boolean
    readonly $isHighlighted?: boolean
}

const StyledTd = styled.td<IStyledText>`
    ${commonCells}
    padding: 20px;
    justify-content: ${({ $isLabel }) => ($isLabel ? 'flex-start' : 'center')};
    min-height: 82px;
    background-color: ${({ $isHighlighted }) =>
        $isHighlighted ? 'var(--info-light-color)' : 'none'};

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding: 12px;
        min-height: 40px;
    }
`

const StyledValueTypography = styled(Typography)<IStyledText>`
    font-weight: ${({ $isLabel }) => ($isLabel ? 400 : 300)};
    text-transform: ${({ $isLabel }) => ($isLabel ? 'capitalize' : 'none')};
    text-overflow: ellipsis;
    overflow: hidden;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 14px;
    }
`

const StyledImg = styled.img`
    max-width: min(200px, 100%);
    max-height: 200px;
`

const StyledAddIcon = styled(Add)`
    fill: var(--primary-dark-color);
    width: 40px;
    height: 40px;
`

const StyledBackTypography = styled(Typography)`
    color: var(--primary-dark-color);
`

const StyledBack = styled.div`
    &:hover {
        ${StyledAddIcon} {
            fill: var(--primary-main-color);
        }

        ${StyledBackTypography} {
            color: var(--primary-main-color);
        }
    }
`

const Comparison: FC<{ meta: IToolMetaFragment; tools: ITool[] }> = ({ meta, tools }) => {
    const { pathname, search } = useLocation()
    const reduxDispatch: AppDispatchType = useDispatch()
    const [showDiff, setShowDiff] = useState(false)
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const columnsCount = isMobile ? tools.length + 2 : 7

    const rows: (string | null)[][] = meta.compare
        .map(({ id }) => [id, ...tools.map((tool) => get(tool, id)?.toString() || null)])
        .map((row) =>
            [...row, null, null, null, null, null, null, null, null].slice(0, columnsCount)
        )

    return (
        <StyledContainer>
            <Grid container>
                <Grid container item justifyContent='space-between' xs={12}>
                    <StyledTitleTypography variant='h4'>
                        {t('comparison:comparison-table')}
                    </StyledTitleTypography>
                    <StyledActions>
                        <StyledDesktopButton
                            variant={showDiff ? 'outlined' : 'contained'}
                            color='primary'
                            onClick={() => setShowDiff(false)}
                        >
                            {t('comparison:all-parameters')}
                        </StyledDesktopButton>
                        <StyledDesktopButton
                            variant={showDiff ? 'contained' : 'outlined'}
                            color='primary'
                            onClick={() => setShowDiff(true)}
                        >
                            {t('comparison:only-differences')}
                        </StyledDesktopButton>
                        <Button
                            variant='outlined'
                            onClick={() => {
                                const parsedSearch = queryString.parse(search)
                                const newSearch = queryString.stringify({
                                    ...parsedSearch,
                                    ids: [],
                                })
                                replaceComparedToolsInLS([])
                                reduxDispatch(setComparedToolIds([]))
                                navigate(`${pathname}${newSearch}`)
                            }}
                        >
                            {t('clear-all')}
                        </Button>
                    </StyledActions>
                </Grid>
            </Grid>
            <StyledDiffFormControlLabel
                control={
                    <Switch
                        checked={showDiff}
                        onChange={(event) => setShowDiff(event.target.checked)}
                        name='checkedB'
                        color='primary'
                    />
                }
                label={t('comparison:only-differences')}
            />
            <StyledTable columnsCount={columnsCount}>
                <StyledHead>
                    <StyledHeaderTr>
                        {[null, ...tools, null, null, null, null, null, null]
                            .slice(0, columnsCount)
                            .map((tool, i) => {
                                if (i === 0) {
                                    return <StyledTh key={0} />
                                }

                                if (i === tools.length + 1) {
                                    return (
                                        <StyledTh key={i}>
                                            <Link to='/'>
                                                <StyledBack>
                                                    <StyledAddIcon />
                                                    <StyledBackTypography>
                                                        {t('comparison:back-to-the-list')}
                                                    </StyledBackTypography>
                                                </StyledBack>
                                            </Link>
                                        </StyledTh>
                                    )
                                }

                                if (!tool) {
                                    return <StyledTh key={i}></StyledTh>
                                }

                                return (
                                    <StyledTh key={i}>
                                        <StyledImg
                                            src={`${process.env.GATSBY_CDN_HOST}/${tool.attributes.code}_md`}
                                        />
                                        <StyledRemoveIcon
                                            onClick={() => {
                                                removeComparedToolFromLS(tool.id)
                                                reduxDispatch(removeComparedToolId(tool.id))
                                            }}
                                        />
                                    </StyledTh>
                                )
                            })}
                    </StyledHeaderTr>
                </StyledHead>
                <StyledBody>
                    {rows.map((row) => {
                        const isEqual = new Set(row.slice(1, tools.length + 1)).size === 1

                        const isAlwaysDiff = alwaysDiff.includes(row[0] || '')

                        if (isEqual && showDiff && !isAlwaysDiff) {
                            return null
                        }

                        return (
                            <StyledTr key={row[0]?.toString()}>
                                {row.map((value, i) => {
                                    const isLabel = order.includes(value?.toString() || '')
                                    const isToolValue = i <= tools.length
                                    const labelText =
                                        isLabel && value !== null
                                            ? t(
                                                  `common:${value
                                                      .replace('attributes.', '')
                                                      .replace('_key', '')
                                                      .toLocaleLowerCase()}`
                                              )
                                            : value

                                    return (
                                        <StyledTd
                                            key={i}
                                            $isLabel={isLabel}
                                            $isHighlighted={
                                                isToolValue &&
                                                !isEqual &&
                                                !showDiff &&
                                                !isAlwaysDiff
                                            }
                                        >
                                            <StyledValueTypography $isLabel={isLabel}>
                                                {isToolValue
                                                    ? labelText === null
                                                        ? '—'
                                                        : t(labelText)
                                                    : null}
                                            </StyledValueTypography>
                                        </StyledTd>
                                    )
                                })}
                            </StyledTr>
                        )
                    })}
                </StyledBody>
            </StyledTable>
        </StyledContainer>
    )
}

export default Comparison
