import { graphql, PageProps, useStaticQuery } from 'gatsby'
import queryString from 'query-string'
import { map, mapValues, groupBy } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import Layout from '../components/common/Layout'
import SEO from '../components/common/SEO'
import Comparison from '../components/Comparison'
import { URL_ARRAY_FORMAT } from '../constants/comparison'
import { fetchTools } from '../helpers/fetch-tools'
import { FilterMetaTypeEnum } from '../interfaces/filters-meta.interface'
import { IData } from '../interfaces/main-page.interface'
import { ITool } from '../interfaces/tool.interface'
import { setComparedToolIds as setComparedToolIdsAction } from '../state/actions/tools/set-compared-tool-ids'
import { AppDispatchType } from '../state/interfaces/app-dispatch.type'
import { IReduxState } from '../state/interfaces/state.interface'
import { replaceComparedToolsInLS } from '../utils/local-storage'
import { CLIENT_ID_COOKIE_NAME } from '../constants/cookies'

const ComparisonPage: FC<PageProps> = ({ location }) => {
    const reduxDispatch: AppDispatchType = useDispatch()
    const [comparedTools, setComparedTools] = useState<ITool[]>([])
    const { comparedToolIds } = useSelector((state: IReduxState) => state.tools)

    const data: IData = useStaticQuery(graphql`
        query {
            build: externalJson {
                ...ExternalFragment
            }
            toolMeta: toolMetaJson {
                ...ToolMetaFragment
            }
        }
    `)

    useEffect(() => {
        const { ids: comparedToolIds } = queryString.parse(location.search, {
            arrayFormat: URL_ARRAY_FORMAT,
        })

        if (!comparedToolIds || comparedToolIds.length === 0) {
            return
        }

        const comparedToolIdsArray = (
            Array.isArray(comparedToolIds) ? comparedToolIds : [comparedToolIds]
        ).map(Number)
        replaceComparedToolsInLS(comparedToolIdsArray)
        reduxDispatch(setComparedToolIdsAction(comparedToolIdsArray))
    }, [])

    const comparedToolIdsStr = comparedToolIds.sort().join(',')

    const [clientIdCookies] = useCookies([CLIENT_ID_COOKIE_NAME])
    const clientId = clientIdCookies[CLIENT_ID_COOKIE_NAME]

    useEffect(() => {
        const fetchComparedTools = async () => {
            if (!comparedToolIds || comparedToolIds.length === 0) {
                setComparedTools([])
                return
            }

            const fetchedTools = await fetchTools(
                {
                    q: '',
                    filtersValues: [
                        {
                            objectType: 'tool',
                            attribute: 'id',
                            type: FilterMetaTypeEnum.LIST,
                            values: comparedToolIds.map((id) => id.toString()),
                        },
                    ],
                    page: 1,
                },
                clientId
            )

            if (!fetchedTools) {
                return
            }

            const toolsMap = mapValues(groupBy(data.build.tools, 'id'), (e) => e[0])
            const comparedTools: ITool[] = map(fetchedTools.results, (r) => toolsMap[r.id])
            setComparedTools(comparedTools)
        }

        fetchComparedTools()
    }, [comparedToolIdsStr])

    if (!comparedTools) {
        return <>Loading...</>
    }

    return (
        <Layout>
            <SEO title='Comparison' />
            <Comparison meta={data.toolMeta} tools={comparedTools} />
        </Layout>
    )
}

export default ComparisonPage
