import { AppDispatchType } from '../../interfaces/app-dispatch.type'

export const setComparedToolIds =
    (toolIds: number[]) =>
    (dispatch: AppDispatchType): void => {
        dispatch({
            type: 'SET_COMPARED_TOOL_IDS',
            payload: toolIds,
        })
    }
